import React from 'react';
import s from './MobileFiltersModal.scss';
import classNames from 'classnames';
import {Filters} from '../../Filters/Filters';

import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../galleryGlobalStrategy';

export interface IMobileFiltersModal extends IGalleryGlobalProps {
  className: string;
  clearFilters: Function;
  closeModal: Function;
  isOpen: boolean;
  shouldShowClearFilters: boolean;
}

@withGlobals
export class MobileFiltersModal extends React.Component<IMobileFiltersModal> {
  public render() {
    const {isOpen, shouldShowClearFilters, clearFilters, closeModal} = this.props;
    const {isPreviewMode} = this.props.globals;

    const classnames = classNames({
      [s.modal]: true,
      [s.open]: isOpen,
      [s.preview]: isPreviewMode,
    });

    return (
      <>
        <div className={classnames} data-hook="mobile-filters-modal">
          <Filters
            closeMobileFiltersModal={() => closeModal()}
            shouldShowClearFiltersButton={shouldShowClearFilters}
            clearFilters={clearFilters}
          />
        </div>
      </>
    );
  }
}
