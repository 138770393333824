import * as React from 'react';
import cx from 'classnames';
import s from './ProductList.scss';
import {IProduct, GridType} from '../../../types/galleryTypes';
import {Announcer} from '@wix/wixstores-client-core/dist/es/src/a11y/announcer';

export interface ProductListGridProps {
  focusAt: number;
  gridType: GridType;
  isMobileOverride: boolean;
  products: IProduct[];
  renderKey: string;
  ProductItem: Function;
}

export enum ProductListGridDataHook {
  List = 'product-list-wrapper',
  Item = 'product-list-grid-item',
}

export class ProductListGrid extends React.Component<ProductListGridProps> {
  private readonly itemRefs = [];
  private a11yAnnouncer: Announcer;

  public static defaultProps = {
    focusAt: -1,
    gridType: GridType.MANUAL,
    isMobileOverride: false,
    products: [],
    renderKey: 'string',
  };

  public componentDidMount() {
    this.a11yAnnouncer = new Announcer('gallery-list-announcer');
  }

  public componentWillUnmount() {
    this.a11yAnnouncer.cleanup();
  }

  public componentDidUpdate(prevProps: ProductListGridProps) {
    if (this.props.focusAt !== prevProps.focusAt) {
      this.focusItem(this.props.focusAt);
    }
  }

  private focusItem(i) {
    this.itemRefs[i] && this.itemRefs[i].focus && this.itemRefs[i].focus();
  }

  public render() {
    const {products, isMobileOverride, gridType, renderKey, ProductItem} = this.props;
    const className = gridType === GridType.AUTO ? s.autoGrid : s.manualGrid;

    return (
      <ul
        className={cx(className, s.grid, {[s.isMobile]: isMobileOverride})}
        data-hook={ProductListGridDataHook.List}
        data-grid-type={gridType}>
        {products.map((product, index) => {
          const id = product ? product.id : `product-${index}`;
          return (
            <li key={`${id}-${renderKey}`} data-hook={ProductListGridDataHook.Item}>
              {product && (
                <ProductItem
                  index={index}
                  product={product}
                  innerRef={ref => (this.itemRefs[index] = ref)}
                  a11yAnnouncer={this.a11yAnnouncer}
                  quantitySRKeys={{
                    total: 'gallery.sr.totalQty',
                    minimumAmount: 'gallery.minimumQuantity.error',
                    maximumAmount: 'gallery.exceedsQuantity.error',
                  }}
                />
              )}
            </li>
          );
        })}
      </ul>
    );
  }
}
